import PropTypes from "prop-types";
import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const Feature = ({ title, description, image, index }) => {
  const image_src = getImage(image);

  if (index % 2 === 0) {
    return (
      <div class="max-w-5xl px-6 py-2 mx-auto">
        <div class="items-center md:flex md:space-x-6">
         
        <div class="md:w-1/2">
            <h3 class="text-2xl  text-black">{title}</h3>
            <p class="max-w-md mt-4 text-black">{description}</p>
          </div>
          
          <div class="mt-8 md:mt-0 md:w-1/2">
            <div class=" items-center justify-center">
              <div class="max-w-md">
                <GatsbyImage
                  image={image_src}
                  alt={description}
                  className="shadow-lg rounded max-w-full h-auto align-middle border-none"
                  style={{
                    transform:
                      "scale(.7) perspective(1040px) rotateY(-11deg) rotateX(0deg) rotate(0deg)",
                  }}
                />
              </div>
            </div>
          </div>

         
        </div>
      </div>
    );
  } else {
    return (
      <div class="max-w-5xl px-6 py-2 mx-auto">
        <div class="items-center md:flex md:space-x-6">
        

        <div class="md:w-1/2">
            <h3 class="text-2xl  text-black">{title}</h3>
            <p class="max-w-md mt-4 text-black">{description}</p>
          </div>
          
          <div class="mt-8 md:mt-0 md:w-1/2">
            <div class=" items-center justify-center">
              <div class="max-w-md">
                <GatsbyImage
                  image={image_src}
                  alt={description}
                  className="shadow-lg rounded max-w-full  align-middle border-none"
                  style={{
                    transform:
                      "scale(.7) perspective(1040px) rotateY(191deg) rotateX(0deg) rotate(0deg)",
                  }}
                />
              </div>
            </div>
          </div>

        
        </div>
      </div>
    );
  }
};

Feature.propTypes = {
  title: PropTypes.string,
};

Feature.defaultProps = {
  description: PropTypes.string,
};

export default Feature;
