import PropTypes from "prop-types";
import React from "react";
import Feature from "./feature";

const FeatureList = ({ features }) =>
  features.map((feature, index) => {
    return <Feature {...feature} index={index} />;
  });

FeatureList.propTypes = {
  title: PropTypes.string,
};

FeatureList.defaultProps = {
  description: PropTypes.string,
};

export default FeatureList;
